@import "../_styles/vars";

.tabs {
  display: flex;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  user-select: none;
  align-items: stretch;
  box-shadow: inset 0 -1px var(--color-border); }

.tabs--stretch  .tab {
  flex: 33% 1 1; }

.tab {
  cursor: pointer;
  white-space: nowrap;
  padding: 16px 12px; }

.tab--active {
  color: var(--color-primary);
  box-shadow: inset 0 -3px var(--color-primary); }

.tab--hasIcon {
  padding: 12px; }

.tab-icon {
  display: block;
  padding: 0;
  margin: 0 auto 12px;
  width: 20px;
  height: 20px; }

.tab-service-icon {
  margin-left: 4px;
  vertical-align: 2px; }
