@import "../_styles/vars";

.ink {
  overflow: hidden;
  opacity: .99;
  position: relative;
  display: block;
  border-radius: inherit; }

.ink-fill {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  opacity: 0;
  background-color: currentColor;
  pointer-events: none; }

.ink-fill--animating {
  transition: transform .6s cubic-bezier(0,0,.2,1), width .3s cubic-bezier(0,0,.2,1), height .3s cubic-bezier(0,0,.2,1), opacity .6s cubic-bezier(0,0,.2,1); }

.ink-fill--focused {
  opacity: .1;
  height: 100%;
  width: 100%; }

.ink--primary .ink-fill {
  background-color: var(--color-primary); }

.ink--greyscale .ink-fill {
  background-color: var(--color-text); }
