@import "../_styles/vars";

.dropDown-container {
  position: fixed;
  z-index: 60; }

.dropDown-content {
  padding: 24px;
  max-height: 40vh;
  overflow-x: auto; }

.dropDown-title {
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-border);
  font-size: 16px;
  font-weight: 500; }


.dropDown-trigger {
  cursor: pointer; }

.dropDown-icon {
  vertical-align: 2px;

  &:not(:first-child) {
    margin-left: 6px; } }
