@import "../_styles/vars";

.layout {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow: hidden; }

.pane {
  flex-grow: 1;
  overflow: hidden; }

.scroll {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;

  > .table {
    margin-right: 18px;

    &:last-child {
      margin-bottom: 16px; } } }

.view {
  flex-grow: 0;
  flex-shrink: 0; }

.content {
  padding: 24px 32px;
  flex-grow: 1; }

.layout--vertical {
  flex-direction: column;

  > .pane {
    height: 100%; } }

.layout-column {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden; }

.layout-column--scroll {
  overflow-y: auto;
  padding-right: 12px; }

.layout-column--small {
  width: 40%;
  max-width: 460px;
  flex-grow: 0;
  flex-shrink: 0; }

.layout-column--half {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0; }

.sidebar {
  height: 100%;
  display: flex;
  width: 28%;
  flex-flow: column; }

.sidebar--filled {
  background-color: var(--color-background-card); }

.sidebar-content {
  padding: 16px 24px; }

.sidebar-header {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 24px;

  .tabs {
    margin: 0 -24px;
    padding: 0 24px; } }

.sidebar-title {
  font-weight: 500;
  font-size: $fontSizeLarge;
  position: relative;
  padding-top: 16px;
  margin-bottom: 8px; }

.sidebar-close {
  position: absolute;
  z-index: 2;
  padding: 8px;
  top: 0;
  right: 8px;
  font-weight: 200;
  font-size: 24px; }
