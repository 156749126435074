@import "../_styles/vars";

.table {
  position: relative;

  .spinner-container {
    width: 100%;
    left: 0;
    top: 0;
    position: absolute; } }

.table--fullHeight {
  height: calc(100% - 16px);
  display: flex;
  flex-flow: column;

  .table-content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden; } }

.table-title {
  height: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  font-size: $fontSizeSmall;
  font-weight: 500;
  color: var(--color-text-light);
  top: 0;
  position: sticky;
  background-color: var(--color-background);
  z-index: 2;

  &:not(:first-child) {
    margin-top: 24px; } }

.table-content {
  border-radius: 2px;
  background-color: var(--color-background-card);
  box-shadow: $boxShadowElevation05; }

.table-header {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  color: var(--color-text-light);
  font-weight: bold;
  font-size: $fontSizeSmall; }

.table-header-selectionStub {
  width: 40px; }

.table-header-cell {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 6px 8px;
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    padding-left: 12px; }

  &:last-child {
    padding-right: 12px; } }

.table-header-cell--fixed {
  flex-grow: 0;
  flex-shrink: 0; }

.table-row {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $tableRowHeight;
  font-weight: 500;
  transition: box-shadow $time;

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border-table); } }

.table-row--disabled {
  position: relative;
  opacity: 0.2; }

.table-row--disabled::after {
  display: block;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

.table-row--selected {
  background-color: var(--color-enabled); }

.table-row--clickable:not(.table-row--active) {
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary-highlight); } }

.table-row--active {
  color: white;
  background-color: var(--color-background-primary);

  .table-cell {
    --color-text: white;
    --color-border: #{rgba(white, .2)};
    --color-background-primary: rgba(0, 0, 0, .5);
    --color-background-gray200: rgba(255, 255, 255, .1); }

  .progress {
    padding: 1px;
    background-color: var(--color-background-card);
    box-shadow: $boxShadowElevation1; } }

.table-row--padded {
  padding: 16px; }

.table-row--dragging {
  background-color: var(--color-background-card);
  //box-shadow: 0px 0px 1px 1px var(--color-primary30)
  box-shadow: $boxShadowElevation3;

  .table-cell--dragHandle {
    cursor: grabbing; } }

.table-cell--dragHandle {
  cursor: grab;

  &:active {
    cursor: grabbing; } }

.table-selection {
  height: 100%;
  flex-shrink: 1; }

.table-selection-content {
  height: 100%;
  transition: transform $time/2 linear, height $time/2 step-end; }

.table-selection-bar {
  position: relative;
  line-height: 24px;
  height: 0;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  font-size: 11px;
  color: var(--color-text-secondary);
  background-color: var(--color-enabled);
  padding-left: 12px;
  font-weight: 500;
  z-index: -1;
  transition: height $time/2 step-end;
  text-transform: uppercase; }

.table-selection-bar-container {
  height: 0; }

.table-selection--active {
  .table-selection-bar {
    height: 24px;
    transition: none; }

  .table-selection-content {
    transform: translateY(24px);
    height: calc(100% - 24px);
    transition: transform $time/2 linear, height $time/2 step-start;
    box-shadow: 0 -1px var(--color-border-table); } }

.table-cell {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  > .tooltip-container {
    margin-top: 0; }

  &:first-child .table-cell-content {
    padding-left: 12px; }

  &:last-child .table-cell-content {
    padding-right: 12px; } }

.table-cell-content {
  padding: 8px 6px;
  white-space: nowrap;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis; }

.table-cell--fixed {
  flex-grow: 0;
  flex-shrink: 0; }

.table-cell--alignRight {
  text-align: right; }

.table-cell--alignCenter {
  text-align: center; }

.check.table-cell {
  width: 40px;
  height: 39px;
  line-height: 40px;
  padding-left: 12px; }
