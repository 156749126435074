@import "../_styles/vars";
@import "../_styles/keyframes";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: $modalZindex; }

.blackout {
  background-color: var(--color-background-blackout);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: modalFadeIn $time $easeMd; }

.modal-close {
  position: absolute;
  right: 4px;
  top: 4px;
  line-height: 32px;
  height: 32px;
  width: 32px;
  text-align: center;
  color: var(--color-text-secondary);
  font-size: 24px;
  z-index: 1; }

.modal-close--circle {
  background-color: var(--color-background-black30);
  border-radius: 100%;
  right: 8px;
  top: 8px;
  color: white; }

.modal-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  z-index: 51; }

.modal-content {
  padding: 40px;
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  > .error {
    width: 500px;
    padding: 24px 32px 32px;
    background-color: var(--color-background-card);
    box-shadow: $boxShadowElevation4;
    animation: cardFadeIn $time $easeMd; }

  > .card {
    width: 500px;
    box-shadow: $boxShadowElevation4;
    animation: cardFadeIn $time $easeMd; }

  > .card--wide {
    width: 700px; }

  > .card--wide-2 {
    width: 800px; }

  > .card--wide-3 {
    width: 900px; }

  > .spinner-container {
    color: white; } }

@keyframes cardFadeIn {
  from {
    transform: translateY(10px);
    opacity: 0; }

  to {
    transform: translateY(0);
    opacity: 1; } }
