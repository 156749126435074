@import "../_styles/vars";

.definition-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;

  &.spinner-container {
    position: static;
    width: 18px;
    height: 18px;
    display: inline-block;
    color: currentColor;
    vertical-align: -4px;

    .spinner {
      width: 18px;
      height: 18px; } } }

.definition--multiLine {
  flex-direction: column;

  .definition-title {
    display: block; }

  .definition-detail {
    margin-left: 0;
    display: block; } }

.definition-title {
  font-weight: 500; }

.definition-detail {
  opacity: $opacityTextSecondary;
  font-size: 90%;
  margin-left: 5px;
  font-weight: normal; }

[dir="rtl"] .definition-detail {
  margin-right: 5px; }

.empty {
  color: var(--color-text-super-light);
  font-weight: normal; }
