@import "../_styles/vars";

.slideDown {
  transition: height $time * .75 $easeMd, opacity $time * .5 ease;
  overflow: hidden; }

.slideDown--restrictMaxHeight {
  max-height: 100%;

  &.slideDown--animateFinish {
    height: 100%;
    overflow: auto; } }

.slideDown--active {
  height: auto;
  opacity: 1; }


.slideDown--popdown {
  transition: height $time * .75 $easeMd, box-shadow $time $easeMd;
  border-radius: 2px;
  transform: translateY(0);
  background-color: var(--color-background-card);

  > .slideDown-container {
    transition: opacity $time ease;
    opacity: 0; }

  &:before {
    position: absolute;
    top: -6px;
    width: 14px;
    height: 14px;
    background-color: var(--color-background-card);
    transform: translateY(32px) rotate(45deg);
    z-index: 1;
    transition: box-shadow $time * .5 ease, transform $time * .75 $easeOutQuad; }

  &.slideDown--active {
    transform: translateY(0);
    box-shadow: $boxShadowElevation3;

    > .slideDown-container {
      opacity: 1; }

    &:before {
      box-shadow: $boxShadowElevation3;
      transform: translateY(0) rotate(45deg); } } }

.slideDown--alignLeft {
  left: 0;

  &:before {
    content: '';
    left: 16px; } }

.slideDown--alignRight {
  right: 0;

  &:before {
    right: 16px; } }

.slideDown--alignCenter {
  left: 50%;
  transform: translate(-50%);

  &:before {
    left: 50%; } }

.dropDown-container {
  position: fixed;
  z-index: 60; }

.dropDown-content {
  padding: 24px;
  max-height: 40vh;
  overflow-x: auto; }

.dropDown-title {
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-border);
  font-size: 16px;
  font-weight: 500; }
