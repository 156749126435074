@import "../_styles/vars";

.modal-video {
  position: relative;

  .spinner-container {
    color: white; } }

.modal-video-src {
  border-radius: 2px;
  height: calc(100vh - 100px);
  box-shadow: $boxShadowElevation3;
  background-color: black; }

.modal-video-src--hidden {
  position: absolute;
  visibility: hidden; }

.modal-video-icon-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  background-color: var(--color-background-black30);
  margin-left: -35px;
  margin-top: -35px;
  text-align: center;
  animation: video-icon-show $time * 3 $easeMd;
  opacity: 0; }

.modal-video-icon {
  width: 32px;
  height: 32px;
  color: white; }

.modal-video-icon--play {
  margin-left: 8px; }

@keyframes video-icon-show {
  from {
    transform: scale(1);
    opacity: 1; }

  to {
    transform: scale(1.5);
    opacity: 0; } }
