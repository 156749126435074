@import "../_styles/vars";

.btnHolder {
  padding: 12px;
  text-align: center;

  .spinner-container {
    height: 36px; } }

.btn {
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
  max-width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--color-background-card);
  color: var(--color-text);
  position: relative;
  padding: 0;
  box-shadow: $boxShadowElevation05;
  transition: box-shadow .3s $easeShadow;

  &:not(.btns):not(.btn--disabled) {
    &:active, &:focus {
      box-shadow: $boxShadowElevation2; } }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: currentColor;
    border-radius: inherit;
    height: 100%;
    opacity: 0;
    transition: opacity .15s linear; } }

.btn--goBack {
  width: 60px; }

.btn--small {
  height: 28px;
  line-height: 28px;

  .btn-content {
    padding: 0 8px; }

  .btn-icon {
    vertical-align: -2px;
    max-width: 16px;
    height: 16px; } }

.btn:active:before,
.btn:focus:before,
.btn--active:before {
  opacity: .1; }

.btn-icon {
  vertical-align: -4px;
  display: inline-block;
  font-size: 1px;
  max-width: 18px;
  height: 18px; }

.btn-icon--offset {
  margin-right: 8px; }

.btn-content {
  position: relative;
  padding: 0 12px;
  z-index: 1;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis; }

.btn--flat {
  background-color: var(--color-background-gray200);
  box-shadow: none; }

.btn--primary {
  background-color: var(--color-background-primary);
  color: white;

  &.btn--disabled {
    background-color: var(--color-background-gray500); } }

.btn--borderless {
  background-color: transparent;
  box-shadow: none !important; }

.btn--borderless.btn--primary {
  color: var(--color-primary); }

.btn--disabled {
  cursor: default;

  &:before {
    content: none; }

  .btn-content {
    opacity: .3; } }

.btn--expandable {
  &:before {
    content: '';
    border: solid transparent;
    border-width: 5px 0 5px 5px;
    border-left-color: black;
    display: inline-block;
    vertical-align: 0;
    transition: transform .3s $easeOutCubic; } }

.btn--toggler {
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;

  &.btn--active {
    color: white;
    background-color: var(--color-primary);
    box-shadow: none !important; } }

.btn-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  font-size: 100px;
  cursor: pointer; }

.btn--noUpcase {
  text-transform: none; }
