@import "../_styles/vars";
@import "../_styles/inputs";

.check {
  @include commonRadioCheck; }

.check--disabled {
  @include radioCheckDisabled; }

.check-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  vertical-align: -2px;
  @include radioCheckBox;

  .ink {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

.check-box-icon {
  display: block; }

.check-input {
  @include radioCheckInput; }

.check-box-icon-check,
.check-box-icon-minus {
  display: none; }

.check-input:checked + .check-box {
  .check-box-icon-empty {
    display: none; }

  .check-box-icon-check {
    display: block; } }

.check-input:indeterminate + .check-box {
  .check-box-icon-empty {
    display: none; }

  .check-box-icon-minus {
    display: block; } }

.check-input[disabled] + .check-box {
  @include radioCheckDisabledRadioCheckBox; }

.check-input[readonly] + .check-box {
  @include radioCheckDisabled; }

.check-input:checked:not([disabled]) + .check-box:not(.check-box--greyscale) {
  color: var(--color-primary); }
