@import "../_styles/vars";

.error {
  height: 100%;
  min-height: 200px;
  display: flex;
  margin: 0 auto;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center; }

.error-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  opacity: .4; }

.error-message:not(:last-child) {
  margin-bottom: 24px; }
