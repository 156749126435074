@import "../_styles/vars.sass";

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  color: var(--color-primary);

  &.btn-icon {
    display: inline-block;
    color: currentColor;
    vertical-align: middle;

    .spinner {
      width: 18px;
      height: 18px; } } }

.spinner {
  display: block;
  position: relative;
  width: 28px;
  height: 28px; }

.spinner-container--inline {
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  position: static;

  .spinner {
    width: 20px;
    height: 20px; } }

.spinner-element {
  display: block;
  animation: spinner-rotate 2s linear infinite;
  height: 100%;
  width: 100%; }

.spinner-path {
  stroke: currentColor;
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: spinner-animation .8s * 8 $easeMd infinite; }

.spinner-message {
  margin-top: 8px;
  color: var(--color-text-secondary); }

@keyframes spinner-rotate {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-rotate-rtl {
  from {
    transform: rotate(-180deg); }

  to {
    transform: rotate(180deg); } }

$step : 100%/8;
$size : 70;
$small : 3;
$quarterOffset : $size * 3 / 4;
$big : $quarterOffset + $small;

@keyframes spinner-animation {
  from {
    stroke-dasharray: $small, $size - $small;
    stroke-dashoffset: 0; }

  #{$step} {
    stroke-dasharray: $big, $size - $big;
    stroke-dashoffset: 0; }

  #{$step * 2} {
    stroke-dasharray: $small, $size - $small;
    stroke-dashoffset: -$quarterOffset; }

  #{$step * 3} {
    stroke-dasharray: $big, $size - $big;
    stroke-dashoffset: -$quarterOffset; }

  #{$step * 4} {
    stroke-dasharray: $small, $size - $small;
    stroke-dashoffset: -2*$quarterOffset; }

  #{$step * 5} {
    stroke-dasharray: $big, $size - $big;
    stroke-dashoffset: -2*$quarterOffset; }

  #{$step * 6} {
    stroke-dasharray: $small, $size - $small;
    stroke-dashoffset: -3*$quarterOffset; }

  #{$step * 7} {
    stroke-dasharray: $big, $size - $big;
    stroke-dashoffset: -3*$quarterOffset; }

  to {
    stroke-dasharray: $small, $size - $small;
    stroke-dashoffset: -4*$quarterOffset; } }
