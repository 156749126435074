@import "../_styles/vars";

.tooltip {
  position: relative; }

.tooltip--inline {
  display: inline-block; }

.tooltip-container {
  position: fixed;
  margin-top: 4px;
  margin-bottom: 4px;
  z-index: 50;
  width: 300px;
  display: flex; }

.tooltip-container--visible {
  visibility: visible;
  opacity: 1; }

.tooltip-container--centered {
  margin-left: -150px;
  justify-content: center; }

[dir="rtl"] .tooltip-container--centered {
  margin-left: -150px; }

.tooltip-container--right {
  justify-content: flex-end; }

.tooltip-container--left {
  justify-content: flex-start; }

.tooltip-arrow {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  border: solid transparent;
  border-width: 0px 4px 4px 4px;
  margin-left: -4px;
  left: 50%;
  top: -4px;
  border-bottom-color: var(--color-background-tooltip); }

.tooltip-arrow--visible {
  animation: tooltip-from-below $time ease;
  opacity: 1;
  visibility: visible; }

.tooltip-arrow--top {
  border-color: var(--color-background-tooltip) transparent transparent;
  border-width: 4px 4px 0px 4px;
  top: auto;
  bottom: -4px; }

.tooltip-bubble {
  display: block;
  position: relative;
  padding: 8px;
  line-height: 1.3;
  background-color: var(--color-background-tooltip);
  border-radius: 2px;
  color: var(--color-text-white);
  font-size: $fontSizeSmall;
  text-align: center;
  z-index: 1;
  animation: tooltip-from-below $time ease; }

@keyframes tooltip-from-below {
  from {
    opacity: 0;
    transform: translateY(5px); }

  to {
    opacity: 1;
    transform: none; } }

@keyframes tooltip-from-above {
  from {
    opacity: 0;
    transform: translateY(-5px); }

  to {
    opacity: 1;
    transform: none; } }
